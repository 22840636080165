import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import Lines from 'components/lines'
import Dinamiza from 'components/colorDinamiza'
import Light from 'components/colorLight'
import style from 'scss/pages/posizioniAperte.module.scss'
import ItemPosition from 'components/itemPosition'
import FormContactCareers from "components/formContactCareers";

class BackendDeveloper extends React.Component{
  render() {

    const { location, data } = this.props

    return(
      <div ref={this.page} className={style.page}>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Backend developer Symfony e/o Laravel" description={'In Dinamiza Digital Agency di Venezia puoi trovare un team competente in ambito sviluppo di ecommerce, applicazioni, siti internet, mettiti in gioco con noi.'} />

          <Lines />

          <div className={`container-fluid ${style.page__content}`}>
            <div className={`row`}>
              <div className={`offset-md-2 col-md-2`}>
                <p className={`has-color-primary-negative ${style.page__content__claimLeft}`}><Light>Backend developer Symfony e/o Laravel</Light></p>
              </div>
              <div className={`offset-md-1 col-md-7 no-padding ${style.page__content__fullScreenSlide}`}>
                <div>
                  <h3>Cerchiamo un backend developer Symfony e/o Laravel</h3>

                  <p>Cerchiamo persone entusiaste del web con almeno 2 anni di esperienza che si integrino facilmente e proficuamente nel nostro team, contribuiscano a sviluppare idee aziendali e ad ottimizzare quelle dei clienti. Nel caso specifico richiediamo affidabilità ed autonomia nella gestione dei progetti, ottime capacità organizzative e di time management.</p>

                  <p>Posti Vacanti: 1</p>

                  <p>Competenze richieste:</p>
                  <ul>
                    <li>Esperienza con framework Symfony 4+, Laravel o framework similare</li>
                    <li>Conoscenza di sistemi di versioning (git)</li>
                    <li>Esperienza nella scrittura di test funzionali e unitari</li>
                    <li>Conoscenza di HTML5, CSS, JavaScript e jQuery</li>
                    <li>Familiarità con l’ambiente Unix/Linux e lo stack LAMP</li>
                    <li>Buona conoscenza della lingua inglese scritta</li>
                  </ul>

                  <p>Nice to have:</p>
                  <ul>
                    <li>Buona conoscenza di React Js</li>
                  </ul>

                  <p>Garantiamo l’affiancamento di personale qualificato.</p>

                  <h4 className={'title'}>Inviaci il curriculum</h4>
                  <FormContactCareers position={'RIF.51 - Backend developer Symfony e/o Laravel'} />

                </div>
              </div>
            </div>
            <div className={`offset-md-5 col-md-7 no-padding ${style.page__content__fullScreenSlide__arrow}`}></div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default BackendDeveloper
